<template>
  <div
    class="modal fade modal-designer"
    :id="`modal-${pageLayout.publicId}`"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    aria-labelledby="modalDesignerLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content">
        <div class="modal-body">
          <DesignerLayoutBase :pageLayout="pageLayout" />
        </div>
        <div
          class="modal-footer d-flex"
          :class="{
            'justify-content-between cp-5': !isLoading,
            'justify-content-end': isLoading,
          }"
        >
          <div class="form-check form-switch" v-if="!isLoading">
            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              id="preview"
              :value="preview"
              v-model="preview"
              :checked="preview"
            />
            <label class="form-check-label fw-bold" for="preview">
              {{
                $t(
                  "Components.PageDesignerModal.Preview",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label
            >
          </div>
          <div>
            <button
              v-if="!this.buttonDisabled"
              type="button"
              class="btn btn-danger btn-close-modal me-2"
              data-bs-dismiss="modal"
              @click="clear()"
            >
              <i class="bi bi-x"></i>
              {{
                $t(
                  "Components.PageDesignerModal.Close",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </button>
            <button
              v-if="errors.length == 0 && !isLoading && !preview"
              type="button"
              class="btn btn-primary designer-record"
              :disabled="this.buttonDisabled || !this.dataHasChanged"
              @click="submit()"
            >
              <span
                v-if="this.buttonDisabled"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <i class="bi bi-save" v-else></i>
              {{
                $t(
                  "Components.PageDesignerModal.SaveChanges",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "@/assets/css/page-designer.css";
import DesignerLayoutBase from "./components/DesignerLayoutBase";
// import $ from "jquery";
import { hideModal } from "@/core/helpers/dom";

export default {
  name: "ModalDesigner",
  components: {
    DesignerLayoutBase,
  },
  props: {
    pageLayout: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      errors: [],
      dataHasChanged: false,
      buttonDisabled: false,
      preview: false,
      isLoading: true,
    };
  },
  methods: {
    clear: function () {
      // if (this.dataHasChanged) {
      //   window.confirm(
      //     this.$t(
      //       "Components.DesignerModal.ConfirmClose",
      //       {},
      //       { locale: this.$store.state.activeLang }
      //     )
      //   )
      //     ? this.success()
      //     : this.shake();
      // }
      this.buttonDisabled = false;
      this.errors = [];
    },
    success: function () {
      this.buttonDisabled = false;
      this.dataHasChanged = false;
      hideModal(document.getElementById(`modal-${this.pageLayout.publicId}`));
      this.$parent.getPageLayouts();
    },
    error: function () {
      this.buttonDisabled = false;
    },
    submit: function () {
      if (!this.dataHasChanged) {
        return;
      }

      this.buttonDisabled = true;

      let pageLayout = { ...this.pageLayout };
      let layout = JSON.parse(pageLayout.layout);

      function clearUnnecessaryProperties(item) {
        if (item && item.items?.length > 0) {
          item.items.forEach((child) => {
            if (
              child.type === "field" ||
              child.type === "lookup" ||
              child.type === "button"
            ) {
              delete child["name"];
              delete child["formulaName"];
              delete child["description"];
              delete child["fieldType"];
            }
            clearUnnecessaryProperties(child);
          });
        }
      }

      clearUnnecessaryProperties({
        items: layout,
      });

      pageLayout.layout = JSON.stringify(layout);
      if (pageLayout.layout === "[]") {
        pageLayout.layout = null;
      }
      let self = this;
      this.$prodGatewayAxios
        .post(`Lcdp-PageLayoutUpdate`, { ...pageLayout })
        .then((response) => {
          const result = response.data;
          if (result.isOk) {
            self.$emit("designerAfterCallMethod");
            self.success();
          } else {
            self.error();
            this.errors.push(result.msg);
          }
        })
        .catch(function (error) {
          this.errors.push(error);
        });
    },
  },
};
</script>
