<template>
  <div
    class="offcanvas offcanvas-start"
    data-bs-scroll="true"
    tabindex="-1"
    :id="`offcanvas-designItemEdit-${pageLayout.publicId}`"
    :aria-labelledby="`offcanvas-designItemEdit-${pageLayout.publicId}-label`"
  >
    <div class="offcanvas-header">
      <h5
        class="offcanvas-title"
        :id="`offcanvas-designItemEdit-${pageLayout.publicId}-label`"
      >
        {{
          $t(
            "Components.PageDesignerModal.EditItem",
            {},
            {
              locale: this.$store.state.activeLang,
            }
          )
        }}
      </h5>
      <button
        type="button"
        class="btn-close text-reset"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>
    <div class="offcanvas-body" v-if="editItem && !isMounted">
      <div
        v-if="
          editItem.type === 'field' ||
          editItem.type === 'lookup' ||
          editItem.type === 'button'
        "
      >
        <div class="mb-3">
          <label
            class="form-label"
            v-if="editItem.type === 'field'"
            :for="`obj-${editItem.id}`"
            >{{
              $t(
                "Components.PageDesignerModal.Field",
                {},
                {
                  locale: this.$store.state.activeLang,
                }
              )
            }}
          </label>
          <label
            class="form-label"
            v-else-if="editItem.type === 'lookup'"
            :for="`obj-${editItem.id}`"
            >{{
              $t(
                "Components.PageDesignerModal.ObjectRelation",
                {},
                {
                  locale: this.$store.state.activeLang,
                }
              )
            }}
          </label>
          <label class="form-label" v-else :for="`obj-${editItem.id}`"
            >{{
              $t(
                "Components.PageDesignerModal.Button",
                {},
                {
                  locale: this.$store.state.activeLang,
                }
              )
            }}
          </label>
          <div
            :id="`obj-${editItem.id}`"
            class="border border-1 rounded p-2"
            :class="vParent.getObjectBorderClass(editItem.type)"
          >
            <div class="d-flex justify-content-between align-items-center">
              <span :class="vParent.getCustomTextClass(editItem.type)"
                ><i
                  class="bi bi-lg m-1"
                  :class="vParent.getCustomObjectIcon(editItem.type)"
                ></i
                >{{ editItem.name }}</span
              >
              <button
                type="button"
                class="btn btn-sm text-dark"
                @click="vParent.openObjectInNewWindow(editItem, true)"
              >
                <i class="bi bi-box-arrow-up-right bi-lg"></i>
              </button>
            </div>
          </div>
        </div>

        <div class="mb-3" v-if="editItem.type === 'field'">
          <label class="form-label" :for="`formulaName-${editItem.id}`"
            >{{
              $t(
                "Components.PageDesignerModal.FormulaName",
                {},
                {
                  locale: this.$store.state.activeLang,
                }
              )
            }}
          </label>
          <input
            type="text"
            class="form-control"
            :id="`formulaName-${editItem.id}`"
            readonly
            v-model="editItem.formulaName"
          />
        </div>

        <div class="mb-3" v-if="editItem.type === 'field'">
          <label class="form-label" :for="`fieldType-${editItem.id}`"
            >{{
              $t(
                "Components.PageDesignerModal.FieldType",
                {},
                {
                  locale: this.$store.state.activeLang,
                }
              )
            }}
          </label>
          <input
            type="text"
            class="form-control"
            :id="`fieldType-${editItem.id}`"
            readonly
            v-model="editItem.fieldType"
          />
        </div>

        <div
          class="mb-3"
          v-if="editItem.type === 'field' && editItem.description"
        >
          <label class="form-label" :for="`desc-${editItem.id}`"
            >{{
              $t(
                "Components.PageDesignerModal.Description",
                {},
                {
                  locale: this.$store.state.activeLang,
                }
              )
            }}
          </label>
          <textarea
            class="form-control"
            :id="`desc-${editItem.id}`"
            rows="4"
            readonly
            v-model="editItem.description"
          ></textarea>
        </div>

        <!-- <div class="mb-3" v-if="editItem.type === 'lookup'">
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                :id="`is-table-sheet-${editItem.id}`"
                :value="editItem.isTableSheet"
                v-model="editItem.isTableSheet"
                :checked="editItem.isTableSheet"
              />
              <label
                class="form-check-label"
                :for="`is-table-sheet-${editItem.id}`"
                >{{
                  $t(
                    "Components.PageDesignerModal.IsItTableSheet",
                    {},
                    {
                      locale: this.$store.state.activeLang,
                    }
                  )
                }}
              </label>
            </div>
          </div> -->
      </div>
      <div
        class="mb-3"
        v-if="
          editItem.type === 'alert' ||
          editItem.type === 'border' ||
          editItem.type === 'accordion' ||
          editItem.type === 'tab'
        "
      >
        <label :for="`definition-name-input-${editItem.id}`" class="form-label">{{
          $t(
            "Components.PageDesignerModal.Name",
            {},
            {
              locale: this.$store.state.activeLang,
            }
          )
        }}</label>
        <input
          type="text"
          class="form-control"
          :id="`definition-name-input-${editItem.id}`"
          placeholder="Just for the definition"
          v-model="editItem.name"
          @click="$event.target.select()"
        />
      </div>
      <div
        class="mb-3"
        v-if="
          editItem.type !== 'field' &&
          editItem.type !== 'lookup' &&
          editItem.type !== 'button'
        "
      >
        <label
          class="form-label"
          :for="`customClasses-${editItem.id}-${editItem.type}`"
          >{{
            $t(
              "Components.PageDesignerModal.CustomClasses",
              {},
              {
                locale: this.$store.state.activeLang,
              }
            )
          }}
        </label>
        <textarea
          :id="`customClasses-${editItem.id}-${editItem.type}`"
          v-model="editItem.customClasses"
          class="form-control"
          rows="1"
          placeholder="Ex. text-start bg-primary"
        >
        </textarea>
      </div>
      <div
        class="mb-3"
        v-if="editItem.type === 'panel' || editItem.type === 'alert'"
      >
        <label :for="`title-input-${editItem.id}`" class="form-label">{{
          $t(
            "Components.PageDesignerModal.Title",
            {},
            {
              locale: this.$store.state.activeLang,
            }
          )
        }}</label>
        <input
          type="text"
          class="form-control"
          :id="`title-input-${editItem.id}`"
          placeholder="Title"
          v-model="editItem.title"
          @click="$event.target.select()"
        />
      </div>
      <div class="mb-3" v-if="editItem.type === 'border'">
        <label :for="`title-input-${editItem.id}`" class="form-label">{{
          $t(
            "Components.PageDesignerModal.Class",
            {},
            {
              locale: this.$store.state.activeLang,
            }
          )
        }}</label>
        <input
          type="text"
          class="form-control"
          :id="`title-input-${editItem.id}`"
          placeholder="rounded border-5"
          v-model="editItem.borderClass"
          @click="$event.target.select()"
        />
        <div :id="`border-help-${editItem.id}`" class="form-text">
          {{
            $t(
              "Components.PageDesignerModal.BorderDescription",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
          <br />
          <a
            href="https://getbootstrap.com/docs/5.1/utilities/borders/"
            target="_blank"
            >{{
              $t(
                "Components.PageDesignerModal.SeeDocument",
                {},
                {
                  locale: this.$store.state.activeLang,
                }
              )
            }}</a
          >
        </div>
      </div>
      <div class="mb-3" v-if="editItem.type === 'alert'">
        <label :for="`icon-input-${editItem.id}`" class="form-label">{{
          $t(
            "Components.PageDesignerModal.Icon",
            {},
            {
              locale: this.$store.state.activeLang,
            }
          )
        }}</label>
        <input
          type="text"
          class="form-control"
          :id="`icon-input-${editItem.id}`"
          placeholder="info-circle"
          v-model="editItem.icon"
          @click="$event.target.select()"
        />
        <div :id="`icon-${editItem.id}`" class="form-text">
          {{
            $t(
              "Components.PageDesignerModal.IconDescription",
              {},
              {
                locale: this.$store.state.activeLang,
              }
            )
          }}
          <a href="https://icons.getbootstrap.com/#icons" target="_blank">
            {{
              $t(
                "Components.PageDesignerModal.Icons",
                {},
                {
                  locale: this.$store.state.activeLang,
                }
              )
            }}</a
          >
        </div>
      </div>
      <div class="mb-3" v-if="editItem.type === 'alert'">
        <label :for="`message-input-${editItem.id}`" class="form-label">{{
          $t(
            "Components.PageDesignerModal.Message",
            {},
            {
              locale: this.$store.state.activeLang,
            }
          )
        }}</label>
        <textarea
          type="text"
          class="form-control"
          :id="`message-input-${editItem.id}`"
          placeholder="Your message"
          v-model="editItem.message"
          @click="$event.target.select()"
        />
      </div>
      <div class="mb-3" v-if="showColumnRanger">
        <label :for="`colRange-${editItem.id}`" class="form-label"
          >{{
            $t(
              "Components.PageDesignerModal.ColumnRange",
              {},
              {
                locale: this.$store.state.activeLang,
              }
            )
          }}
          (12/{{ editItem.columnSize }})</label
        >
        <input
          type="range"
          class="form-range"
          :id="`colRange-${editItem.id}`"
          min="0"
          max="12"
          v-model="editItem.columnSize"
          :aria-describedby="`range-help-${editItem.id}`"
        />
        <div :id="`range-help-${editItem.id}`" class="form-text">
          {{
            $t(
              "Components.PageDesignerModal.ColumRangeDescription",
              {},
              {
                locale: this.$store.state.activeLang,
              }
            )
          }}
          <br />
          <a
            href="https://getbootstrap.com/docs/5.1/layout/grid/"
            target="_blank"
            >{{
              $t(
                "Components.PageDesignerModal.SeeDocument",
                {},
                {
                  locale: this.$store.state.activeLang,
                }
              )
            }}</a
          >
        </div>
      </div>
      <div class="mb-3" v-if="editItem.color !== undefined">
        <label :for="`color-${editItem.id}`" class="form-label">{{
          $t(
            "Components.PageDesignerModal.Color",
            {},
            {
              locale: this.$store.state.activeLang,
            }
          )
        }}</label>
        <br />

        <div
          :id="`color-${editItem.id}`"
          class="btn-group-vertical col-12 gap-2"
          :aria-describedby="`container-help-${editItem.id}`"
        >
          <div
            class="btn-group-vertical col-12 gap-2"
            v-for="(colorClass, index) in colors"
            :key="index"
          >
            <input
              type="radio"
              class="btn-check"
              :name="`color-${editItem.id}`"
              :id="`${colorClass}-outlined-${editItem.id}`"
              :autocomplete="this.$isAutoComplete"
              :value="colorClass"
              v-model="editItem.color"
              :checked="editItem.color === colorClass"
            />
            <label
              :class="`btn btn-outline-${colorClass}`"
              :style="colorClass === 'light' ? 'border: 1px solid black' : ''"
              :for="`${colorClass}-outlined-${editItem.id}`"
              >{{ colorClass }}</label
            >
          </div>
        </div>
        <div :id="`color-${editItem.id}`" class="form-text">
          {{
            $t(
              "Components.PageDesignerModal.ColorDescription",
              {},
              {
                locale: this.$store.state.activeLang,
              }
            )
          }}
          <br />
          <a
            href="https://getbootstrap.com/docs/5.1/customize/color/#theme-colors"
            target="_blank"
            >{{
              $t(
                "Components.PageDesignerModal.SeeDocument",
                {},
                {
                  locale: this.$store.state.activeLang,
                }
              )
            }}</a
          >
        </div>
      </div>
      <div class="mb-3" v-if="editItem.type === 'panel'">
        <div class="form-check form-switch">
          <input
            class="form-check-input"
            type="checkbox"
            role="switch"
            :id="`collapsed-${editItem.id}`"
            :value="editItem.isCollapsed"
            v-model="editItem.isCollapsed"
            :checked="editItem.isCollapsed"
          />
          <label class="form-check-label" :for="`collapsed-${editItem.id}`"
            >{{
              $t(
                "Components.PageDesignerModal.Collapsed",
                {},
                {
                  locale: this.$store.state.activeLang,
                }
              )
            }}
          </label>
        </div>
        <div class="mt-2">
          <label
            class="form-label mb-0"
            :for="`labelClassNames-${editItem.id}-${editItem.type}`"
            >{{
              $t(
                "Components.PageDesignerModal.LabelClassNames",
                {},
                {
                  locale: this.$store.state.activeLang,
                }
              )
            }}
            <a
              target="_blank"
              href="https://getbootstrap.com/docs/5.0/utilities/api/"
            >
              <i class="bi bi-info-circle-fill text-primary"></i>
            </a>
          </label>
          <input
            type="text"
            class="form-control"
            placeholder="Ex. text-start text-muted"
            :id="`labelClassNames-${editItem.id}-${editItem.type}`"
            v-model="editItem.labelClassNames"
          />
        </div>
      </div>
      <div class="mb-3" v-if="editItem.type === 'container'">
        <label :for="`container-size-${editItem.id}`" class="form-label">{{
          $t(
            "Components.PageDesignerModal.Size",
            {},
            {
              locale: this.$store.state.activeLang,
            }
          )
        }}</label>
        <br />
        <div
          :id="`container-size-${editItem.id}`"
          class="btn-group-vertical col-12 gap-2"
          :aria-describedby="`container-help-${editItem.id}`"
        >
          <div
            class="btn-group-vertical col-12 gap-2"
            v-for="(containerClassName, index) in containerClassTypes"
            :key="index"
          >
            <input
              type="radio"
              class="btn-check"
              :name="`container-size-${editItem.id}`"
              :id="`${containerClassName}-outlined-${editItem.id}`"
              :autocomplete="this.$isAutoComplete"
              :value="containerClassName"
              v-model="editItem.className"
              :checked="editItem.className === containerClassName"
            />
            <label
              class="btn btn-outline-secondary"
              :for="`${containerClassName}-outlined-${editItem.id}`"
              >{{ containerClassName }}</label
            >
          </div>
        </div>
        <div :id="`container-help-${editItem.id}`" class="form-text">
          {{
            $t(
              "Components.PageDesignerModal.ContainerDescription",
              {},
              {
                locale: this.$store.state.activeLang,
              }
            )
          }}
          <br />
          <a
            href="https://getbootstrap.com/docs/5.1/layout/containers/"
            target="_blank"
            >{{
              $t(
                "Components.PageDesignerModal.SeeDocument",
                {},
                {
                  locale: this.$store.state.activeLang,
                }
              )
            }}</a
          >
        </div>
      </div>
      <div class="mb-3" v-if="editItem.type === 'column'">
        <label :for="`column-size-${editItem.id}`" class="form-label">{{
          $t(
            "Components.PageDesignerModal.Size",
            {},
            {
              locale: this.$store.state.activeLang,
            }
          )
        }}</label>
        <br />
        <div
          :id="`column-size-${editItem.id}`"
          class="btn-group-vertical col-12 gap-2"
          :aria-describedby="`column-help-${editItem.id}`"
        >
          <div
            class="btn-group-vertical col-12 gap-2"
            v-for="(columnClassName, index) in columnClassTypes"
            :key="index"
          >
            <input
              type="radio"
              class="btn-check"
              :name="`column-size-${editItem.id}`"
              :id="`${columnClassName}-outlined-${editItem.id}`"
              :autocomplete="this.$isAutoComplete"
              :value="columnClassName"
              v-model="editItem.classType"
              :checked="editItem.classType === columnClassName"
            />
            <label
              class="btn btn-outline-secondary"
              :for="`${columnClassName}-outlined-${editItem.id}`"
              >{{ columnClassName }}</label
            >
          </div>
        </div>
        <div :id="`column-help-${editItem.id}`" class="form-text">
          {{
            $t(
              "Components.PageDesignerModal.ColumnDescription",
              {},
              {
                locale: this.$store.state.activeLang,
              }
            )
          }}
          <br />
          <a
            href="https://getbootstrap.com/docs/5.1/layout/grid/#how-it-works"
            target="_blank"
          >
            {{
              $t(
                "Components.PageDesignerModal.HowItWork",
                {},
                {
                  locale: this.$store.state.activeLang,
                }
              )
            }}</a
          >
          -
          <a
            href="https://getbootstrap.com/docs/5.1/layout/grid/#grid-options"
            target="_blank"
          >
            {{
              $t(
                "Components.PageDesignerModal.GridOptions",
                {},
                {
                  locale: this.$store.state.activeLang,
                }
              )
            }}</a
          >
        </div>
      </div>
      <div class="mb-3" v-if="editItem.type === 'tab'">
        <label :for="`tab-type-${editItem.id}`" class="form-label">{{
          $t(
            "Components.PageDesignerModal.Type",
            {},
            {
              locale: this.$store.state.activeLang,
            }
          )
        }}</label>
        <br />
        <div
          :id="`tab-type-${editItem.id}`"
          class="btn-group-vertical col-12 gap-2"
        >
          <div
            class="btn-group-vertical col-12 gap-2"
            v-for="(columnClassName, index) in tabTypes"
            :key="index"
          >
            <input
              type="radio"
              class="btn-check"
              :name="`tab-type-${editItem.id}`"
              :id="`${columnClassName}-outlined-${editItem.id}`"
              :autocomplete="this.$isAutoComplete"
              :value="columnClassName"
              v-model="editItem.tabType"
              :checked="editItem.tabType == columnClassName"
              :disabled="editItem.tabType == columnClassName"
            />
            <label
              class="btn btn-outline-secondary"
              :for="`${columnClassName}-outlined-${editItem.id}`"
              >{{ columnClassName }}</label
            >
          </div>
        </div>
      </div>
      <div class="mb-3" v-if="editItem.type === 'tab'">
        <div class="form-check form-switch">
          <input
            class="form-check-input"
            type="checkbox"
            role="switch"
            :id="`tab-justify-${editItem.id}`"
            :value="editItem.isJustified"
            v-model="editItem.isJustified"
            :checked="editItem.isJustified"
          />
          <label class="form-check-label" :for="`tab-justify-${editItem.id}`"
            >{{
              $t(
                "Components.PageDesignerModal.Justified",
                {},
                {
                  locale: this.$store.state.activeLang,
                }
              )
            }}
          </label>
        </div>
        <div class="form-check form-switch">
          <input
            class="form-check-input"
            type="checkbox"
            role="switch"
            :id="`tab-nextStepRequiredFieldValidation-${editItem.id}`"
            :value="editItem.isNextStepRequiredFieldValidation"
            v-model="editItem.isNextStepRequiredFieldValidation"
            :checked="editItem.isNextStepRequiredFieldValidation"
          />
          <label
            class="form-check-label"
            :for="`tab-nextStepRequiredFieldValidation-${editItem.id}`"
            >{{
              $t(
                "Components.PageDesignerModal.IsNextStepRequiredFieldValidation",
                {},
                {
                  locale: this.$store.state.activeLang,
                }
              )
            }}
          </label>
        </div>
      </div>
      <div
        class="mb-3"
        v-if="editItem.type === 'tab' || editItem.type === 'accordion'"
      >
        <label :for="`tab-item-${editItem.id}`" class="form-label">{{
          $t(
            "Components.PageDesignerModal.ChildItems",
            {},
            {
              locale: this.$store.state.activeLang,
            }
          )
        }}</label>
        <br />
        <draggable
          tag="ul"
          :list="editItem.items"
          item-key="id"
          ghost-class="ghost"
          handle=".handle"
          v-bind="{
            group: { name: 'items' },
            sort: true,
            disabled: false,
            animation: 300,
          }"
          class="list-group"
        >
          <template #item="{ element }">
            <li class="list-group-item">
              <div class="d-flex justify-content-between align-items-center">
                <i class="bi bi-arrows-move handle" />
                <input
                  type="text"
                  class="form-control mx-2"
                  v-model="element.name"
                  @click="$event.target.select()"
                />
                <button
                  @click="this.vParent.deleteItem(editItem.items, element)"
                  type="button"
                  class="btn btn-sm text-danger position-absolute end-0"
                >
                  &#x2715;
                </button>
              </div>
              <textarea
                type="text"
                class="form-control mt-1"
                placeholder="Plaintext or html content"
                v-model="element.content"
                @click="$event.target.select()"
              />
              <div class="mt-2">
                <label
                  class="form-label mb-0"
                  :for="`labelClassNames-${editItem.id}-${editItem.type}`"
                  >{{
                    $t(
                      "Components.PageDesignerModal.LabelClassNames",
                      {},
                      {
                        locale: this.$store.state.activeLang,
                      }
                    )
                  }}
                  <a
                    target="_blank"
                    href="https://getbootstrap.com/docs/5.0/utilities/api/"
                  >
                    <i class="bi bi-info-circle-fill text-primary"></i>
                  </a>
                </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Ex. text-start text-muted"
                  :id="`labelClassNames-${editItem.id}-${editItem.type}`"
                  v-model="element.labelClassNames"
                />
              </div>
              <div class="mt-2">
                <label
                  class="form-label"
                  :for="`fontColor-${editItem.id}-${editItem.type}`"
                  >{{
                    $t(
                      "Components.PageDesignerModal.FontColor",
                      {},
                      {
                        locale: this.$store.state.activeLang,
                      }
                    )
                  }}
                </label>
                <br />
                <div class="dx-viewport">
                  <DxColorBox
                    :showClearButton="true"
                    :openOnFieldClick="true"
                    :id="`fontColor-${editItem.id}-${editItem.type}`"
                    :value="element.fontColor"
                    v-model="element.fontColor"
                  />
                </div>
              </div>
              <div class="mt-2">
                <label
                  class="form-label"
                  :for="`linearGradient-${editItem.id}-${editItem.type}`"
                  >{{
                    $t(
                      "Components.PageDesignerModal.LinearGradient",
                      {},
                      {
                        locale: this.$store.state.activeLang,
                      }
                    )
                  }}
                  <button
                    v-if="!String.isNullOrWhiteSpace(element.linearGradient)"
                    type="button"
                    @click="gradientPickerOnResetElement(element)"
                    class="btn btn-danger btn-xs ms-1"
                  >
                    <i class="bi bi-arrow-counterclockwise ms-1"></i>
                    {{
                      $t(
                        "Components.PageDesignerModal.ResetLinearGradient",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </button>
                </label>
                <br />
                <GradientPicker
                  v-if="!linearGradientRestarting"
                  :value="element.linearGradient"
                  @onSelect="gradientPickerOnSelectElement($event, element)"
                />
                <input
                  type="hidden"
                  class="form-control"
                  :id="`linearGradient-${editItem.id}-${editItem.type}`"
                  v-model="element.linearGradient"
                />
              </div>
            </li>
          </template>

          <template #footer>
            <div class="btn-group" role="group">
              <button
                class="btn"
                @click="this.vParent.addNewChildItem(`${editItem.type}Item`)"
              >
                <i class="bi bi-plus-lg text-success" />
              </button>
            </div>
          </template>
        </draggable>
      </div>
      <div
        v-if="
          editItem.type === 'lookup' ||
          editItem.type === 'alert' ||
          editItem.type === 'panel' ||
          editItem.type === 'border' ||
          editItem.type === 'button'
        "
      >
        <template v-if="editItem.type === 'lookup'">
          <div class="mb-3">
            <label
              class="form-label"
              :for="`fontColor-${editItem.id}-${editItem.type}`"
            >
              {{
                $t(
                  "Components.PageDesignerModal.TableTitleFontColor",
                  {},
                  {
                    locale: this.$store.state.activeLang,
                  }
                )
              }}
            </label>
            <br />
            <div class="dx-viewport">
              <DxColorBox
                :showClearButton="true"
                :openOnFieldClick="true"
                :id="`fontColor-${editItem.id}-${editItem.type}`"
                :value="editItem.fontColor"
                v-model="editItem.fontColor"
              />
            </div>
          </div>
          <div class="mb-3">
            <label
              class="form-label"
              :for="`tableFontColor-${editItem.id}-${editItem.type}`"
              >{{
                $t(
                  "Components.PageDesignerModal.TableFontColor",
                  {},
                  {
                    locale: this.$store.state.activeLang,
                  }
                )
              }}
            </label>
            <br />
            <div class="dx-viewport">
              <DxColorBox
                :showClearButton="true"
                :openOnFieldClick="true"
                :id="`tableFontColor-${editItem.id}-${editItem.type}`"
                :value="editItem.tableFontColor"
                v-model="editItem.tableFontColor"
              />
            </div>
          </div>
        </template>
        <template v-else>
          <div class="mb-3">
            <label
              class="form-label"
              :for="`fontColor-${editItem.id}-${editItem.type}`"
              >{{
                $t(
                  "Components.PageDesignerModal.FontColor",
                  {},
                  {
                    locale: this.$store.state.activeLang,
                  }
                )
              }}
            </label>
            <br />
            <div class="dx-viewport">
              <DxColorBox
                :showClearButton="true"
                :openOnFieldClick="true"
                :id="`fontColor-${editItem.id}-${editItem.type}`"
                :value="editItem.fontColor"
                v-model="editItem.fontColor"
              />
            </div>
          </div>
        </template>
        <div class="mb-3">
          <label
            class="form-label"
            :for="`linearGradient-${editItem.id}-${editItem.type}`"
            >{{
              $t(
                "Components.PageDesignerModal.LinearGradient",
                {},
                {
                  locale: this.$store.state.activeLang,
                }
              )
            }}
            <button
              v-if="!String.isNullOrWhiteSpace(editItem.linearGradient)"
              type="button"
              @click="gradientPickerOnReset"
              class="btn btn-danger btn-xs ms-1"
            >
              <i class="bi bi-arrow-counterclockwise ms-1"></i>
              {{
                $t(
                  "Components.PageDesignerModal.ResetLinearGradient",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </button>
          </label>
          <br />
          <GradientPicker
            v-if="!linearGradientRestarting"
            :value="editItem.linearGradient"
            @onSelect="gradientPickerOnSelect"
          />
          <input
            type="hidden"
            class="form-control"
            :id="`linearGradient-${editItem.id}-${editItem.type}`"
            v-model="editItem.linearGradient"
          />
        </div>
      </div>
      <div v-if="editItem.type === 'field' || editItem.type === 'button'">
        <div class="mb-3">
          <label
            class="form-label"
            :for="`labelClassNames-${editItem.id}-${editItem.type}`"
            >{{
              $t(
                "Components.PageDesignerModal.LabelClassNames",
                {},
                {
                  locale: this.$store.state.activeLang,
                }
              )
            }}
          </label>
          <input
            type="text"
            class="form-control"
            placeholder="Ex. text-start text-muted"
            :id="`labelClassNames-${editItem.id}-${editItem.type}`"
            v-model="editItem.labelClassNames"
          />
        </div>
        <div class="mb-3" v-if="editItem.type !== 'button'">
          <label
            class="form-label"
            :for="`valueClassNames-${editItem.id}-${editItem.type}`"
            >{{
              $t(
                "Components.PageDesignerModal.ValueClassNames",
                {},
                {
                  locale: this.$store.state.activeLang,
                }
              )
            }}
          </label>
          <input
            type="text"
            class="form-control"
            placeholder="Ex. text-lowercase text-uppercase"
            :id="`valueClassNames-${editItem.id}-${editItem.type}`"
            v-model="editItem.valueClassNames"
          />
        </div>
        <div :id="`color-${editItem.id}`" class="form-text">
          {{
            $t(
              "Components.PageDesignerModal.SetYourUtilitiesOfTheItem",
              {},
              {
                locale: this.$store.state.activeLang,
              }
            )
          }}
          <br />
          <a
            href="https://getbootstrap.com/docs/5.0/utilities/api/"
            target="_blank"
            >{{
              $t(
                "Components.PageDesignerModal.SeeDocument",
                {},
                {
                  locale: this.$store.state.activeLang,
                }
              )
            }}</a
          >
        </div>
        <div class="mb-2 mt-2" v-if="editItem.type !== 'button'">
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              :id="`isRequired-${editItem.id}-${editItem.type}`"
              v-model="editItem.isRequired"
              :value="editItem.isRequired"
              :checked="editItem.isRequired"
            />
            <label
              class="form-check-label"
              :for="`isRequired-${editItem.id}-${editItem.type}`"
              >{{
                $t(
                  "Components.PageDesignerModal.IsRequired",
                  {},
                  {
                    locale: this.$store.state.activeLang,
                  }
                )
              }}</label
            >
          </div>
        </div>
        <div class="mb-2 mt-2">
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              :id="`isDisabled-${editItem.id}-${editItem.type}`"
              v-model="editItem.isDisabled"
              :value="editItem.isDisabled"
              :checked="editItem.isDisabled"
            />
            <label
              class="form-check-label"
              :for="`isDisabled-${editItem.id}-${editItem.type}`"
              >{{
                $t(
                  "Components.PageDesignerModal.IsDisabled",
                  {},
                  {
                    locale: this.$store.state.activeLang,
                  }
                )
              }}</label
            >
          </div>
        </div>
        <div class="mb-2 mt-2">
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              :id="`isHidden-${editItem.id}-${editItem.type}`"
              v-model="editItem.isHidden"
              :value="editItem.isHidden"
              :checked="editItem.isHidden"
            />
            <label
              class="form-check-label"
              :for="`isHidden-${editItem.id}-${editItem.type}`"
              >{{
                $t(
                  "Components.PageDesignerModal.IsHidden",
                  {},
                  {
                    locale: this.$store.state.activeLang,
                  }
                )
              }}</label
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import panelTextColor from "./helpers/PanelTextColorPicker";
import draggable from "vuedraggable";
import GradientPicker from "@/components/custom/gradient-picker/GradientPicker.vue";
import { DxColorBox } from "devextreme-vue/color-box";

export default {
  name: "DesignItemEdit",
  props: ["pageLayout"],
  components: {
    draggable,
    DxColorBox,
    GradientPicker,
  },
  data() {
    return {
      uId: String.newGuid(),
      isMounted: false,
      editItem: null,
      editParent: null,
      vParent: null,

      uniqueElementId: String.newGuid(),
      containerClassTypes: [
        "container",
        "container-sm",
        "container-md",
        "container-lg",
        "container-xl",
        "container-xxl",
        "container-fluid",
      ],
      columnClassTypes: [
        "col",
        "col-xs",
        "col-sm",
        "col-md",
        "col-lg",
        "col-xl",
        "col-xxl",
      ],
      colors: [
        "primary",
        "secondary",
        "success",
        "danger",
        "warning",
        "info",
        "light",
        "dark",
      ],
      tabTypes: ["nav-pills", "nav-tabs"],
      linearGradientRestarting: false,
    };
  },
  mounted: function () {},
  watch: {
    uId() {
      this.isMounted = true;
      setTimeout(() => {
        this.isMounted = false;
      }, 1);
    },
    editItem: {
      handler(editedItem) {
        if (editedItem?.columnSize !== undefined) {
          editedItem.className =
            editedItem.columnSize > 0
              ? `${editedItem.classType}-${editedItem.columnSize}`
              : editedItem.classType;

          if (
            (editedItem.type === "tab" ||
              editedItem.type === "alert" ||
              editedItem.type === "panel" ||
              editedItem.type === "border" ||
              editedItem.type === "accordion" ||
              editedItem.type === "field" ||
              editedItem.type === "lookup" ||
              editedItem.type === "button") &&
            this.editParent &&
            this.editParent.type === "column"
          ) {
            this.editParent.columnSize = editedItem?.columnSize;
            this.editParent.className =
              this.editParent.columnSize > 0
                ? `${this.editParent.classType}-${editedItem.columnSize}`
                : this.editParent.classType;
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    gradientPickerOnSelect(value) {
      this.editItem.linearGradient = value;
    },
    gradientPickerOnReset() {
      this.linearGradientRestarting = true;
      setTimeout(() => {
        this.linearGradientRestarting = false;
      }, 10);
      this.editItem.linearGradient = null;
    },
    gradientPickerOnSelectElement(value, element) {
      element.linearGradient = value;
    },
    gradientPickerOnResetElement(element) {
      this.linearGradientRestarting = true;
      setTimeout(() => {
        this.linearGradientRestarting = false;
      }, 10);
      element.linearGradient = null;
    },
  },
  computed: {
    textColor() {
      return panelTextColor.get(this.editItem);
    },
    showColumnRanger() {
      return (
        this.editItem.columnSize !== undefined &&
        this.editParent &&
        this.editParent.type === "column"
      );
    },
  },
};
</script>
