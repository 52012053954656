<template>
  <div class="card">
    <div
      class="card-header"
      :class="`bg-${element.color}`"
      :style="{
        background: !String.isNullOrWhiteSpace(element.linearGradient)
          ? element.linearGradient
          : '',
        color: !String.isNullOrWhiteSpace(element.fontColor)
          ? element.fontColor
          : '',
      }"
    >
      <span
        :class="textColor"
        :style="{
          color: !String.isNullOrWhiteSpace(element.fontColor)
            ? element.fontColor + '!important'
            : '',
        }"
        >{{ element.title }}
      </span>
    </div>
    <div class="card-body" :class="{ collapse: element.isCollapsed }">
      <PreviewLayoutChild
        :customObjectItems="customObjectItems"
        :items="element.items"
        v-if="element.container == true"
      />
    </div>
  </div>
</template>

<script>
import PreviewLayoutChild from "../PreviewLayoutChild.vue";
import panelTextColor from "../../helpers/PanelTextColorPicker";

export default {
  props: ["element", "customObjectItems"],
  name: "PanelItem",
  components: {
    PreviewLayoutChild,
  },
  computed: {
    textColor() {
      return panelTextColor.get(this.element);
    },
  },
};
</script>

<style scoped>
.card-header {
  padding: 1rem 1.5rem !important;
}
</style>
