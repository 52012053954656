<template>
  <!-- <div class="card_____"> -->
  <div class="card-header">
    <div
      class="nav card-header-tabs"
      :class="[
        element.tabType,
        { 'nav-justified': element.isJustified },
        { 'pb-2': element.tabType === 'nav-pills' },
        { 'border-0': element?.items?.length === 0 },
      ]"
      :id="element.id"
      role="tablist"
    >
      <button
        v-for="(item, index) in element.items"
        :key="item.id"
        class="nav-link"
        :class="{ active: index === 0 }"
        :id="`nav-${index}-tab`"
        data-bs-toggle="tab"
        :data-bs-target="`#nav-${item.id}`"
        :aria-controls="item.id"
        type="button"
        role="tab"
        aria-selected="true"
        :style="{
          background: !String.isNullOrWhiteSpace(item.linearGradient)
            ? item.linearGradient
            : '',
          color: !String.isNullOrWhiteSpace(item.fontColor)
            ? item.fontColor
            : '',
        }"
      >
        {{ item.name }}
      </button>
    </div>
  </div>
  <div class="card-body">
    <div class="tab-content" :id="`nav-tabContent-${element.id}`">
      <div
        v-for="(item, index) in element.items"
        :key="item.id"
        class="tab-pane fade"
        :class="{ 'show active': index === 0 }"
        :id="`nav-${item.id}`"
        role="tabpanel"
        :aria-labelledby="`nav-${item.id}-tab`"
      >
        <div v-html="item.content"></div>
        <PreviewLayoutChild
          :customObjectItems="customObjectItems"
          :items="item.items"
        />
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import PreviewLayoutChild from "../PreviewLayoutChild.vue";
// TODO: bu kısım original bootstrap tab ile değiştirilecek
export default {
  props: ["element", "customObjectItems"],
  name: "TabItem.vue",
  components: {
    PreviewLayoutChild,
  },
};
</script>
