<template>
  <div class="d-flex justify-content-between new-block">
    <div>
      <h2 class="float-start">
        {{
          $t(
            "CustomObjects.PageLayouts",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </h2>
    </div>
    <div>
      <router-link
        :to="{
          path: '/ProjectDesign/PageLayout/List',
        }"
        class="btn btn-action-list-page"
      >
        <i class="bi bi-list"></i>
        {{
          $t(
            "PageLayouts.AllData",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </router-link>
      <router-link
        :to="{
          name: 'CustomObjectNewPageLayout',
          params: { customObjectId: this.$route.params.customObjectId },
        }"
        class="btn btn-success btn-action-new-page"
      >
        <i class="bi bi-plus"></i>
        {{
          $t(
            "PageLayouts.NewButton",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </router-link>
    </div>
  </div>
  <JsonViewerModal
    :type="jsonViewer.type"
    :publicId="jsonViewer.selectedPageLayoutId"
    :formulaName="jsonViewer.selectedPageLayoutFormulaName"
    :isChanged="jsonViewer.refresh"
  />
  <JsonPasteModal :pastePublicId="jsonCopyPaste.selectedPageLayoutId" />
  <ModalDesigner
    v-for="pageLayout in pageLayoutList"
    :key="pageLayout.publicId"
    :pageLayout="pageLayout"
  />
  <Grid
    ref="grid"
    :settings="gridSettings"
    :columns="gridColumns"
    :rows="pageLayoutList"
    @changeOrderSuccessful="getPageLayouts"
    @onJsonViewerButtonClick="onJsonViewerButtonClick"
    @onJsonViewerCopyPageLayoutButtonClick="
      onJsonViewerCopyPageLayoutButtonClick
    "
    @onDesignerButtonClick="onDesignerButtonClick"
    @onJsonViewerPastePageLayoutButtonClick="
      onJsonViewerPastePageLayoutButtonClick
    "
    @clonePageLayout="clonePageLayout"
  />
</template>
<script>
import { createToast } from "mosha-vue-toastify";
import $ from "jquery";
import ModalDesigner from "@/components/page-designer/DesignerModal";
import JsonViewerModal from "@/components/custom/json-viewer/JsonViewerModal";
import JsonPasteModal from "@/components/custom/json-viewer/JsonPasteModal.vue";
import { showModal } from "@/core/helpers/dom";

export default {
  name: "CustomObjectPageLayouts",
  components: {
    JsonViewerModal,
    ModalDesigner,
    JsonPasteModal,
  },
  data() {
    return {
      gridSettings: {
        action: "CustomObjectPageLayouts",
        requestUrl: String.format(""),
        requestUrlRouteParamNames: [
          { key: "customObjectPublicId", value: "customObjectId" },
        ],
        routeRequiredParameters: [
          {
            key: "pageLayoutId",
            value: "publicId",
            isRouteParameter: false,
          },
          {
            key: "customObjectId",
            value: "customObjectId",
            isRouteParameter: true,
          },
        ],

        serialNoLink: `#/CustomObject/EditPageLayout/__CUSTOMOBJECTPUBLICID__&pageLayoutId=`,

        allowSearchPanel: true,
        allowExcelExport: false,
        allowPaging: false,
        allowDeleting: true,
        autoOrderProcess: false,
        deleteActionUrl: "/Lcdp-PageLayoutDelete",

        isGatewayRequest: true,

        allowDragAndDrop: true,
        changeOrderUrl: "Lcdp-PageLayoutChangeOrder",
        changeOrderModelParentProperties: [
          { key: "parentPublicId", value: "customObjectId" },
        ],
        buttons: [
          {
            name: "json-viewer",
            cssClass: "btn-dark json-viewer",
            iconClass: "bi-code-slash",
            routeButton: false,
            emitMethodName: "onJsonViewerButtonClick",
            attributes: {
              "data-bs-toggle": "modal",
              "data-bs-target": "#modalJsonViewer",
            },
            iconAttributes: {
              "data-bs-html": "true",
              "data-bs-toggle": "tooltip",
              "data-bs-placement": "top",
              title: this.$t(
                "PageLayouts.CopyApiRequestForLayout",
                {},
                { locale: this.$store.state.activeLang }
              ),
            },
          },
          {
            name: "json-viewer-page-layout-copy",
            cssClass: "btn-info json-viewer-page-layout-copy",
            iconClass: "bi bi-clipboard",
            routeButton: false,
            emitMethodName: "onJsonViewerCopyPageLayoutButtonClick",
            attributes: {
              "data-bs-toggle": "modal",
              "data-bs-target": "#modalJsonViewer",
            },
            iconAttributes: {
              "data-bs-html": "true",
              "data-bs-toggle": "tooltip",
              "data-bs-placement": "top",
              title: this.$t(
                "PageLayouts.LayoutCopyTemplateButon",
                {},
                { locale: this.$store.state.activeLang }
              ),
            },
          },
          {
            name: "json-viewer-page-layout-paste",
            cssClass: "btn-light json-viewer-page-layout-paste",
            iconClass: "bi bi-clipboard-check",
            routeButton: false,
            emitMethodName: "onJsonViewerPastePageLayoutButtonClick",
            attributes: {
              "data-bs-toggle": "modal",
              "data-bs-target": "#jsonPasteModal",
            },
            iconAttributes: {
              "data-bs-html": "true",
              "data-bs-toggle": "tooltip",
              "data-bs-placement": "top",
              title: this.$t(
                "PageLayouts.LayoutPasteButton",
                {},
                { locale: this.$store.state.activeLang }
              ),
            },
          },
          {
            name: "page-layout-clone",
            cssClass: "btn-light page-layout-clone",
            iconClass: "bi bi-subtract",
            routeButton: false,
            emitMethodName: "clonePageLayout",
            iconAttributes: {
              "data-bs-html": "true",
              "data-bs-toggle": "tooltip",
              "data-bs-placement": "top",
              title: this.$t(
                "PageLayouts.LayoutCopyButton",
                {},
                { locale: this.$store.state.activeLang }
              ),
            },
          },
          {
            name: "designer",
            cssClass: "btn-primary designer",
            iconClass: "bi-columns-gap",
            routeButton: false,
            emitMethodName: "onDesignerButtonClick",
            iconAttributes: {
              "data-bs-html": "true",
              "data-bs-toggle": "tooltip",
              "data-bs-placement": "top",
              title: this.$t(
                "Components.PageDesignerModal.ModalTitle",
                {},
                { locale: this.$store.state.activeLang }
              ),
            },
          },
          {
            name: "edit",
            cssClass: "btn-warning",
            iconClass: "bi-pencil-square",
            routeButton: true,
            routeObj: {
              name: "CustomObjectEditPageLayout",
              params: {
                customObjectId: "",
                pageLayoutId: "",
              },
            },
          },
        ],
      },
      gridColumns: [
        {
          text: this.$t(
            "PageLayouts.Name",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "name",
          type: "string",
          visible: true,
          width: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "PageLayouts.FormulaName",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "formulaName",
          type: "string",
          visible: true,
          width: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "PageLayouts.Main",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isMain",
          type: "boolean",
          visible: true,
          width: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "PageLayouts.IsMobileLayout",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isMobileLayout",
          type: "boolean",
          visible: true,
          width: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "PageLayouts.IsApiModelEnabled",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isApiModelEnabled",
          type: "boolean",
          visible: true,
          width: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "PageLayouts.DependentPageLayoutName",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "dependentPageLayoutName",
          type: "string",
          visible: false,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.IsActive",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isActive",
          type: "boolean",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.CreatedBy",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "createdByName",
          type: "string",
          visible: false,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.CreatedAt",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "createdAt",
          type: "datetime",
          visible: false,
          width: 0,
          template: "",
          format: this.$store.getters._dateTimeFormat,
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.UpdatedBy",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "updatedByName",
          type: "string",
          visible: false,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.UpdatedAt",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "updatedAt",
          type: "datetime",
          visible: false,
          width: 0,
          template: "",
          format: this.$store.getters._dateTimeFormat,
          textAlign: "",
        },
      ],
      selectedPageLayoutId: null,
      selectedPageLayoutFormulaName: null,
      pageLayoutList: [],
      jsonViewerType: "",
      pageLayoutDataToPaste: {},
      isChanged: "",
      jsonViewer: {},
      jsonCopyPaste: {},
    };
  },
  methods: {
    onJsonViewerButtonClick(rowData) {
      this.jsonViewer = {
        type: "pagelayout",
        refresh: String.newGuid(),
        selectedPageLayoutId: rowData.publicId,
        selectedPageLayoutFormulaName: rowData.formulaName,
      };
      // this.selectedPageLayoutId = rowData.publicId;
      // this.selectedPageLayoutFormulaName = rowData.formulaName;
      // this.jsonViewerType = "pagelayout";
      // this.isChanged = this.jsonViewerType + this.selectedPageLayoutId;
    },
    onJsonViewerCopyPageLayoutButtonClick(rowData) {
      this.jsonViewer = {
        type: "pagelayoutCopy",
        refresh: String.newGuid(),
        selectedPageLayoutId: rowData.publicId,
        selectedPageLayoutFormulaName: rowData.formulaName,
      };
    },
    onJsonViewerPastePageLayoutButtonClick(rowData) {
      this.jsonCopyPaste = {
        type: "pagelayoutPaste",
        refresh: String.newGuid(),
        selectedPageLayoutId: rowData.publicId,
        selectedPageLayoutFormulaName: rowData.formulaName,
      };
    },
    clonePageLayout(rowData) {
      this.$prodGatewayAxios
        .post(`/Lcdp-PageLayoutClone?id=${rowData.publicId}`)
        .then((response) => {
          var result = response.data;
          if (result.isOk) {
            this.getPageLayouts();
          } else {
            createToast(result.message, {
              showIcon: true,
              position: "top-right",
              type: "danger",
              transition: "zoom",
            });
          }
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    onDesignerButtonClick(rowData) {
      this.pageLayoutList.find((x) => x.publicId == rowData.publicId).reload =
        String.newGuid();
      showModal(document.getElementById(`modal-${rowData.publicId}`));
    },
    //TODO: Child and grid components are used.
    getPageLayouts() {
      this.$prodGatewayAxios
        .get(
          String.format(
            "/Lcdp-PageLayoutList?customObjectPublicId={0}",
            this.$route.params.customObjectId
          )
        )
        .then((response) => {
          this.pageLayoutList = response.data.items;
        })
        .catch(function (error) {
          //alert(error);
        });
    },
  },
  mounted() {
    this.getPageLayouts();
  },
  watch: {
    "$route.params.customObjectId"(newValue) {
      if (!String.isNullOrWhiteSpace(newValue)) {
        this.getPageLayouts();
      }
    },
  },
};
</script>
